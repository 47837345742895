<template>
    <div class="indeter">
        <div class="chapterDetail_box">
           
            <div class="chapterDetail_body">
                <div class="chapterDetail_title_card"  v-html="list_item.subjectTitle"></div>
            </div>
        </div>
        <div class="popup-box" :style="boxPositon=='up'?'bottom:-350px':'bottom:0px'">
            <div class="popup-box_up" @click="setPosition">
                <img class="chapter_cirle-logo"  src="@/assets/all_Icon/drawable-xxhdpi/zong_he_icon.png" />
            </div>
            <van-tabs v-model="active" :line-height="0">
                <van-tab :title="setString(res,itemIndex)" :key="itemIndex" v-for="(res, itemIndex) in list_item.subjectList">
                    <analysisinputRowsCom :subjectId="list_item.subjectId" :itemIndex="itemIndex"  :itemData="res"></analysisinputRowsCom>
                </van-tab>
            </van-tabs>
        </div>
    </div>
</template>

<script>
import { add_sign,add_collect,deleted_collect} from "@/api/chapter.js";
import analysisinputRowsCom from '@/components/analysis_com/analysis_inputRows.vue';
import {
  subject_analysis
} from "@/api/questionbook.js";
export default {
        components: {
            analysisinputRowsCom
        },
        name: "cal",
        props: ['subjectId'],
        data() {
            return {
                boxPositon:"up",
                active:0,
                calList:[],
                isSignSelect:0,
                isCollectSelect:0,
                list_item:{},
             
            }
        },
       
        mounted() {
            this.init();
            
        },

        methods: {
            setString(res,indexItem){
                let val = JSON.stringify(indexItem+1);
                return val;
            },
            setPosition(){
                if(this.boxPositon == "up"){
                    this.boxPositon = "down";
                }else{
                    this.boxPositon = "up";
                }

            },
            init(){
                
                subject_analysis(this.subjectId).then((res)=>{
                    this.list_item = res.data.data;
                })
                
            },
            
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.chapter_cirle-logo{
  width:36px;
  height: 15px;
}
.chapterDetail_title_card{
     margin: 5px 14px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #141414;
}
.chapter_shoucang_box{
  width:18px;
  height: 16px;
  margin-right: 15px;
}

.bj_sc_box{
    display: flex;
    justify-content: space-between;
}
.magin_L{
    margin-left: 14px;
}
.magin_R{
    margin-right: 14px;
}
.cal_score{
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #141414;

}
.cal_input{
    width: 100px;
    height: 35px;
    border: 1px solid #dddddd;
    margin-left: 20px;
    border-radius: 3px;
    text-align: center;
}
.submit_box{
    width: 150px;
    height: 35px;
    background-color: #5F7DFF;
    border-radius: 5px;
    text-align: center;
    line-height: 35px;
    color: #ffffff;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
}
.cal_bottom_box{
    width: 100%;
    height: 50px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}
.indeter{
    width: 100%;
    height: calc(100vh - 46px);
    position: relative;
    overflow: hidden;
    .popup-box{
        position: absolute;
        bottom: -350px;
        width: 100%;
        height: 535px;
        background-color:#ffffff;
        box-shadow: 2px 2px 50px #f8f8f8;
        .popup-box_up{
            width: 100%;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 10px;
        }
        /deep/ .van-tab{
            flex:none;
            width: 25px;
            height: 25px;
            border: 1px solid #cdc5c5;
            margin-left: 14px;
        }
        /deep/ .van-tabs__nav--line{
            height: 20px;
            padding-bottom:0px
        }
        /deep/ .van-tabs__line{
            background-color: #ffffff;
        }
        /deep/ .van-tab--active{
            background-color:#5F7DFF;
            color: #ffffff;
            border: none;
        }
        /deep/ .van-cell{
            padding: 10px 14px;
            
        }
        /deep/ .van-field__control{
            background-color: #f8f8f8;
            text-indent: 15px;
            line-height: 32px;
        }
        /deep/ .van-field__word-limit{
            background-color: #f8f8f8;
            margin-top: 0px;
        }
    }
}

.margin_v{
    margin-left: 12px;
}
.margin_v1{
    margin-left: 10px;
}

.chapterDetail_box{
    display: flex;
    width: 100%;
    height: calc(100vh - 46px);
    background: #ffffff;
    flex-direction: column;
    position: relative;
    .box_with{
        display: flex;
        width: 100%;
        height: 45px;
        background: #ffffff;
        .title_box{
            margin: 0px 14px;
            height: 45px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #141414;
        }

    }
    .chapterDetail_body_box{
        width:100%;
        display: flex;
        flex-direction: column;
    }
    .chapterDetail_body{
        width: 100%;
        position: absolute;
        bottom: 0px;
        top: 0px;
        background-color: #f8f8f8;
        .chapterDetail_title_card{
            margin: 17px 14px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #141414;


        }
        .chapterDetail_card{
            display: flex;
            flex-direction: column;
            margin: 0 14px 17px 14px;
            background: white;
            height: 55px;
            justify-content: center;
            
            .answer_item{
                display: flex;
                flex-direction: row;
                align-items: center;
                .answer_continer{
                    width: 24px;
                    height: 24px;
                    border: 1px solid #363636;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .answer_name{
                    font-size: 14px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #141414;
                    line-height: 24px;
                }
            }
            

        }

    }
    .min{
      font-size: 12px !important;
    }
    .max{
      font-size: 18px !important;
    }
}
</style>
