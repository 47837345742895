<template>
   <div>
        <van-nav-bar
            title="习题解析"
            left-arrow
            @click-left="onClickLeft"
        />
        <analysisRadioCom v-if="this.$route.query.classify == '1' || this.$route.query.classify == '3'" :subjectId="this.$route.query.id"></analysisRadioCom>
        <analysisCheckboxCom v-if="this.$route.query.classify == '2'" :subjectId="this.$route.query.id"></analysisCheckboxCom>
        <analysisCalCom v-if="this.$route.query.classify == '5' 
            || this.$route.query.classify == '6'
            || this.$route.query.classify == '7'
            || this.$route.query.classify == '8'
            || this.$route.query.classify == '9'
            || this.$route.query.classify == '10'
            || this.$route.query.classify == '11'" :subjectId="this.$route.query.id"></analysisCalCom>
        <analysisInderCom v-if="this.$route.query.classify == '4'" :subjectId="this.$route.query.id"></analysisInderCom>    
        
    </div>
   
   
   
</template>

<script>
import analysisRadioCom from '@/components/analysis_com/analysis_radio.vue';  
import analysisCheckboxCom from '@/components/analysis_com/analysis_checkbox.vue';
import analysisCalCom from '@/components/analysis_com/analysis_cal.vue';
import analysisInderCom from '@/components/analysis_com/analysis_inder.vue';
import {
  subject_analysis
} from "@/api/questionbook.js";
export default {
    components: {
        analysisRadioCom,
        analysisCheckboxCom,
        analysisCalCom,
        analysisInderCom
    },
    data() {
        return {
            subjectId:'',
        }
    },
    name: 'analysis',
    methods: {
        onClickLeft() {
            this.$router.go(-1);
        },
        init(){
            //this.subjectId = this.$route.query.id;
        },
    },
    created(){
        
        

    },
    mounted () {
        this.init();
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.text_analysis_title{
    margin: 17px 14px;
    font-size: 15px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #141414;
    .text_analysis_body{
        margin-top: 10px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #141414;
    }
}

.chapter_shoucang_box{
  width:18px;
  height: 16px;
  margin-right: 15px;
}
.right_line{
    border-right: 1px solid #DDDDDD;
}

.bj_sc_box{
    display: flex;
    justify-content: space-between;
}
.margin_v{
    margin-left: 12px;
}
.margin_v1{
    margin-left: 10px;
}
.flex_with{
    flex:0.3
}
.flex_with_center{
    flex: 0.4;
    justify-content: center;
    display: flex;
}
.flex_right{
    display: flex;
    justify-content: right;
}
.chapterDetail_answerNumber_box{
    display: flex;
    flex-direction: column;
    margin: 0 14px 0px 14px;
    background: white;
    height: 67px;
    justify-content: center;
    
    .allAnswerNumber_box{
        height: 23px;
        margin: 0 21px 0px 21px;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;

    }
}
.chapterDetail_answer_box{
    display: flex;
    flex-direction: row;
    margin: 0 14px 0px 14px;
    background: white;
    height: 67px;
    justify-content: center;
    align-items: center;
    .chapterDetail_answer_box_item{
        height: 43px;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        .my_answer_title{
            height: 20px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #E84423;
        }
        .my_answer{
            margin-top: 3px;

        }
        .yes_answer_title{
            height: 20px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #387CEF;
        }
        .all_answer_title{
            height: 20px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
        }
    }
    
}
.chapterDetail_box{
    display: flex;
    width: 100%;
    height: 100vh;
    background: #ffffff;
    flex-direction: column;
    position: relative;
    .box_with{
        display: flex;
        width: 100%;
        height: 45px;
        background: #ffffff;
        .title_box{
            margin: 0px 14px;
            height: 45px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #141414;
        }

    }
    .chapterDetail_body_box{
        width:100%;
        display: flex;
        flex-direction: column;
    }
    .chapterDetail_body{
        width: 100%;
        position: absolute;
        bottom: 0px;
        top: 45px;
        background-color: #f8f8f8;
        .chapterDetail_title_card{
            margin: 17px 14px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #141414;


        }
        .chapterDetail_card{
            display: flex;
            flex-direction: column;
            margin: 0 14px 10px 14px;
            background: white;
            height: 55px;
            justify-content: center;
            
            .answer_item{
                display: flex;
                flex-direction: row;
                align-items: center;
                .answer_continer{
                    width: 24px;
                    height: 24px;
                    border: 1px solid #363636;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .answer_name{
                    font-size: 14px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #141414;
                    display: flex;
                    align-items: center;
                }
                .row_width{
                    width: calc(100% - 48px);
                }
            }
            

        }

    }
    .min{
      font-size: 12px !important;
    }
    .max{
      font-size: 18px !important;
    }
}

</style>
