var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('van-nav-bar',{attrs:{"title":"习题解析","left-arrow":""},on:{"click-left":_vm.onClickLeft}}),(this.$route.query.classify == '1' || this.$route.query.classify == '3')?_c('analysisRadioCom',{attrs:{"subjectId":this.$route.query.id}}):_vm._e(),(this.$route.query.classify == '2')?_c('analysisCheckboxCom',{attrs:{"subjectId":this.$route.query.id}}):_vm._e(),(this.$route.query.classify == '5' 
         || this.$route.query.classify == '6'
         || this.$route.query.classify == '7'
         || this.$route.query.classify == '8'
         || this.$route.query.classify == '9'
         || this.$route.query.classify == '10'
         || this.$route.query.classify == '11')?_c('analysisCalCom',{attrs:{"subjectId":this.$route.query.id}}):_vm._e(),(this.$route.query.classify == '4')?_c('analysisInderCom',{attrs:{"subjectId":this.$route.query.id}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }